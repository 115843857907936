import { format } from "date-fns";
import { pathOr } from "ramda";
import { graphql } from "gatsby";
import { lighten, setLightness } from "polished";
import { hr } from "date-fns/esm/locale";
import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { colors } from "../styles/colors";
import IndexLayout from "../components/layouts";
import { Wrapper } from "../components/Wrapper";
import SiteNav, { SiteNavMain } from "../components/header/SiteNav";
import { inner, outer, SiteMain } from "../styles/shared";
import { Footer } from "../components/Footer";
import parse from "html-react-parser";
import Img from "gatsby-image/withIEPolyfill";
import ImageGallery from "../components/ImageGallery";
import { Link } from "gatsby";

const PageTemplate = props => {
  const post = pathOr(null, ["allWordpressPost", "edges", "0", "node"], props.data);
  const thumbnails = pathOr(null, ["thumbnails", "edges", "0", "node"], props.data);
  const images = pathOr(null, ["images", "edges", "0", "node", "acf", "gallery"], props.data);
  const allPosts = pathOr(null, ["allPosts", "edges"], props.data);

  const date = new Date(post.date);
  // 2018-08-20
  const datetime = format(date, "yyyy-MM-dd", { locale: hr });
  // 20 AUG 2018
  const displayDatetime = format(date, "dd LLL yyyy", { locale: hr });

  let previous = null;
  let next = null;

  allPosts.forEach((post, index) => {
    if (post.node.id === props.pathContext.postId) {
      const previousIndex = index - 1 < 0 ? null : index - 1;
      const nextIndex = index + 1 > allPosts.length - 1 ? null : index + 1;
      previous = previousIndex ? allPosts[previousIndex].node : null;
      next = nextIndex ? allPosts[nextIndex].node : null;
    }
  });

  return (
    <IndexLayout className="post-template">
      {/* <Helmet>
        TODO: go check helmet in other project
      </Helmet> */}
      <Wrapper css={PostTemplate}>
        <header className="site-header">
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isPost postTitle={post.title} />
            </div>
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <div css={inner}>
            {/* TODO: no-image css tag? */}
            <article css={[PostFull, !post.featured_media && NoImage]}>
              <PostFullHeader className="post-full-header">
                <PostFullTags className="post-full-tags"></PostFullTags>
                <PostFullTitle className="post-full-title">{post.title}</PostFullTitle>
                <PostFullByline className="post-full-byline">
                  <section className="post-full-byline-content">
                    <section className="post-full-byline-meta">
                      <h4 className="author-name">{post.author.name}</h4>
                      <div className="byline-meta-content">
                        <time className="byline-meta-date" dateTime={datetime}>
                          {displayDatetime}
                        </time>
                        <span className="byline-reading-time">
                          <span className="bull">&bull;</span> 20 min
                        </span>
                      </div>
                    </section>
                  </section>
                </PostFullByline>
              </PostFullHeader>
              {post.featured_media && (
                <PostFullImage>
                  <Img
                    fluid={post.featured_media.localFile.childImageSharp.fluid}
                    objectFit="cover"
                    alt=""
                  />
                </PostFullImage>
              )}
              <PostContent>{parse(post.content)}</PostContent>
              {images && <ImageGallery thumbnails={thumbnails.acf.gallery} images={images} />}
              <PostSuggestion>
                {previous && (
                  <Link to={previous.slug}>
                    <PostSuggestionContainer>
                      <h2>{previous.title}</h2>
                      <a>{parse(previous.excerpt)}</a>
                    </PostSuggestionContainer>
                  </Link>
                )}
                {next && (
                  <Link to={next.slug}>
                    <PostSuggestionContainer>
                      <h2>{next.title}</h2>
                      <a>{parse(next.excerpt)}</a>
                    </PostSuggestionContainer>
                  </Link>
                )}
              </PostSuggestion>
            </article>
          </div>
        </main>

        {/* TODO */}
        {/* <ReadNext
          tags={post.frontmatter.tags}
          relatedPosts={props.data.relatedPosts}
          pageContext={props.pageContext}
        /> */}

        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

const PostTemplate = css`
  .site-main {
    margin-top: 64px;

    padding-bottom: 4vw;
  }
`;

const PostContent = styled.div`
  padding: 0 100px;

  @media (max-width: 760px) {
    padding: 0;
  }
`;

export const PostFull = css`
  position: relative;
  z-index: 50;

  .read-more {
    border-bottom: 1px solid;
    padding: 50px 0;
  }
`;

export const NoImage = css`
  .post-full-content {
    padding-top: 0;
  }

  .post-full-content:before,
  .post-full-content:after {
    display: none;
  }
`;

export const PostFullHeader = styled.header`
  position: relative;
  margin: 0 auto;
  padding: 70px 170px 50px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  @media (max-width: 1170px) {
    padding: 60px 11vw 50px;
  }

  @media (max-width: 800px) {
    padding-right: 5vw;
    padding-left: 5vw;
  }

  @media (max-width: 500px) {
    padding: 20px 0 35px;
  }
`;

const PostFullTags = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* color: var(--midgrey); */
  color: ${colors.midgrey};
  font-size: 1.3rem;
  line-height: 1.4em;
  font-weight: 600;
  text-transform: uppercase;
`;

const PostFullByline = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 35px 0 0;
  padding-top: 15px;
  /* border-top: 1px solid color(var(--lightgrey) l(+10%)); */
  border-top: 1px solid ${lighten("0.1", colors.lightgrey)};

  .post-full-byline-content {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
  }

  .post-full-byline-content .author-list {
    justify-content: flex-start;
    padding: 0 12px 0 0;
  }

  .post-full-byline-meta {
    margin: 2px 0 0;
    /* color: color(var(--midgrey) l(+10%)); */
    color: ${colors.blue};
    font-size: 1.2rem;
    line-height: 1.2em;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  .post-full-byline-meta h4 {
    margin: 0 0 3px;
    font-size: 1.3rem;
    line-height: 1.4em;
    font-weight: 500;
  }

  .post-full-byline-meta h4 a {
    /* color: color(var(--darkgrey) l(+10%)); */
    color: ${lighten("0.1", colors.darkgrey)};
  }

  .post-full-byline-meta h4 a:hover {
    /* color: var(--darkgrey); */
    color: ${colors.darkgrey};
  }

  .post-full-byline-meta .bull {
    display: inline-block;
    margin: 0 4px;
    opacity: 0.6;
  }
`;

export const PostFullTitle = styled.h1`
  margin: 0 0 0.2em;
  color: ${setLightness("0.05", colors.darkgrey)};
  @media (max-width: 500px) {
    margin-top: 0.2em;
    font-size: 3.3rem;
  }
`;

const PostFullImage = styled.div`
  margin: 25px 0 50px;
  text-align: center;

  img {
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 1);
  }

  @media (max-width: 1170px) {
    margin: 25px -6vw 50px;
    border-radius: 0;
    img {
      max-width: 1170px;
      width: 100%;
    }
  }

  @media (max-width: 800px) {
    margin-bottom: 30vw;
    height: 400px;
  }
  @media (max-width: 650px) {
    margin-bottom: 20vw;
  }
  @media (max-width: 500px) {
    margin-bottom: 4vw;
    height: 350px;
  }
`;

const PostSuggestion = styled.div`
  display: flex;

  a {
    color: black;

    &:hover {
      text-decoration: none;
    }
  }
`;

const PostSuggestionContainer = styled.div`
  padding: 20px;
  border-top: 1px solid ${lighten("0.1", colors.lightgrey)};
  margin-top: 30px;

  &:hover {
    transition: 0.3s;
    box-shadow: 1px 1px 15px 1px rgba(0, 0, 0, 0.23);
  }
`;
export const query = graphql`
  query($postId: String) {
    allWordpressPost(filter: { id: { eq: $postId } }) {
      edges {
        node {
          id
          date
          slug
          link
          title
          content
          excerpt
          categories {
            name
          }
          author {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 900, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    thumbnails: allWordpressPost(filter: { id: { eq: $postId } }) {
      edges {
        node {
          acf {
            gallery {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    images: allWordpressPost(filter: { id: { eq: $postId } }) {
      edges {
        node {
          acf {
            gallery {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    allPosts: allWordpressPost {
      edges {
        node {
          id
          title
          excerpt
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PageTemplate;
